import React from "react"
import PropTypes from "prop-types"

// Components
import { graphql } from "gatsby"
import Layout from "../components/layout"

import * as styles from "../styles/categories.module.scss"
import CategoryList from "../components/categorylist"
import { createCategoryNameMap } from "../components/functions/createconst"
import CreateList from "../components/createlist"

const Categories = ({ pageContext, data, location }) => {


    const categorynamemap = createCategoryNameMap()

    const categorylevel = pageContext.categorylevel
    const category = pageContext.category
    const posts = categorylevel ? data.Category2.nodes : data.Category1.nodes
    const siteTitle = data.site.siteMetadata.title
    const categoryHeader = `${categorynamemap[category]}`
    const categorygroup = data.categorygroup.group
    const totalCount = categorylevel ? data.Category2.totalCount : data.Category1.totalCount


    return (
        <Layout location = {location} title = {siteTitle}>
            <div className={styles.bodywrapper}>
                <h1 className = {styles.categorytitle}>{categoryHeader}</h1>

                <CategoryList categorygroup={categorygroup}></CategoryList>

                <div>
                    {categoryHeader}について{totalCount}件の投稿があります
                </div>

                <CreateList posts = {posts}></CreateList>        
            </div>
        </Layout>
    )
}

Categories.propTypes = {
    pageContext: PropTypes.shape({
        
        category: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
    allMdx: PropTypes.shape({
        totalCount: PropTypes.number.isRequired,
        edges: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
                slug: PropTypes.string.isRequired,
            }),
            }),
        }).isRequired
        ),
    }),
    }),
}

export default Categories

export const pageQuery = graphql`
query(
    $category: String
    ) {
    site{
        siteMetadata{
            title
        }
    }

    categorygroup: allMdx(limit: 2000) {
        group(field: frontmatter___category2) {
            fieldValue
            totalCount
        }
    }

    Category1: allMdx(
    limit: 2000
    sort: { fields: [frontmatter___date], order: DESC }
    filter: { frontmatter: { category1 : { in: [$category] } } }
    ) 
    {
        nodes {
            id
            excerpt
            fields {
                slug
            }
            frontmatter {
                category2
                category1
                date(formatString: "MMMM DD, YYYY")
                description
                title
                tags
                hero{
                    childImageSharp{
                        gatsbyImageData(
                            width: 600
                            placeholder: NONE
                        )
                    }
                }
            }
        }
        totalCount
    }

    Category2: allMdx(
        limit: 2000
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { category2 : { in: [$category] } } }
        ) {
            nodes {
                id
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    category2
                    category1
                    date(formatString: "MMMM DD, YYYY")
                    description
                    title
                    tags
                    hero{
                        childImageSharp{
                            gatsbyImageData(
                                width: 600
                                placeholder: NONE
                            )
                        }
                    }
                }
            }
            totalCount
        }
}
`